// @ts-nocheck

import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import ChatPageController, { Props } from "../src/ChatPageController.web";
import Header from "../../dashboard/src/Seller/AddPropertyStepper/Header.web";
import { viewBuyerImage, imageMail, imageAttachment, attachmentImg, sendImg } from "./assets";
// import AppLoader from "../../../components/src/AppLoader.web";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ScheduleTour from "../../dashboard/src/Buyer/ScheduleTour.web";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { videoImg } from "../../dashboard/src/assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CounterOffer from "./CounterOffer.web";
class chatSchedule extends ChatPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      <>
        <div className="flex">
          <div>
            {/* Slider  */}
            {localStorage.getItem("role") === "buyer" ? (
              <SidebarBuyer menuCollapsed={true} />
            ) : (
              <SidebarSeller menuCollapsed={true} />
            )}
          </div>
          <div className="stepper-outer">
            <Header
              flag={this.state.HeaderDropdownFlag}
              toggle={this.doToggleHeaderDropdown}
            />
            <div className="new-msg-block">
              <div className="msg-notification">
                <button type="button" className="btn orange-fill-btn">
                  Messages
                  <span>{this.state.displayChat?.length}</span>
                </button>
              </div>
              <div className="new-msg">
                <button type="button" className="rounded-fill-btn">
                  New Message
                </button>
              </div>
            </div>
            <div className="chat-block-wrapper">
              <div className="chat-list-wrapper">
                <div className="search-wrapper">
                  <Formik
                    initialValues={{}}
                    // validationSchema={Yup.object().shape(this.state.emailSchema)}
                    onSubmit={(values) => console.log(values)}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Field
                          type="text"
                          name="Keywords"
                          placeholder="MLS, Yard, etc…"
                          className="custom-input secondary-input"
                          onChange={(e) => this.doSearch(e.target.value)}
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
                {!isEmpty(this.state.displayChat) && this.state.displayChat.map(conv => <>
                  <div
                    className={`chat-block ${this.state.coversationId === conv.id ? "active-chat" : ""}`}
                    key={conv.id}
                    onClick={() => {
                      // this.setState({ recipientId: conv?.attributes?.recipient_id }, () => console.log("recipient_id", conv?.attributes?.recipient_id))
                      this.doRecipientId(conv?.attributes?.recipient_id);
                      this.doConversationId(conv.id)
                    }
                    }>
                    <span className="image-block">
                      <img src={viewBuyerImage} alt="" />
                    </span>
                    <div className="right-block">
                      <div className="top-block">
                        <span className="name">{`${conv?.attributes?.communicator?.data?.attributes?.first_name ? conv?.attributes?.communicator?.data?.attributes?.first_name : "John"} ${conv?.attributes?.communicator?.data?.attributes?.last_name ? conv?.attributes?.communicator?.data?.attributes?.last_name : "Dow"}`}</span>
                        <span className="mail-icon">
                          <img src={conv?.attributes?.communicator?.data?.attributes?.profile_picture ? conv?.attributes?.communicator?.data?.attributes?.profile_picture : imageMail} alt="" />
                        </span>
                        <span className="msg-time">{moment(conv?.attributes?.last_message?.data?.attributes?.created_at).format("h:mm A")}</span>
                      </div>
                      <div className="msg-content">
                        <p>
                          {
                            conv?.attributes?.last_message?.data?.attributes?.body
                              ? conv?.attributes?.last_message?.data?.attributes?.body
                              : (
                                conv?.attributes?.last_message?.data?.attributes?.offer_data
                                  ? (localStorage.getItem("role") == "seller" ? "You have got an offer" : "Your offer sent")
                                  : (conv?.attributes?.last_message?.data?.attributes?.tour_data
                                    ? (localStorage.getItem("role") == "seller" ? "You have got a vist request" : "Your visit request sent")
                                    : "No Message"
                                  )
                              )
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </>)}
              </div>
              <div className="chat-content">
                <div className="content-header">
                  <span className="title">Seller Made Counter Offer</span>
                  <div className="btn-wrapper">
                    <button type="button" className="btn orange-fill-btn">
                      Resolved
                    </button>
                    <button type="button" className="gray-border-btn" onClick={() => this.deleteConv(this.state.coversationId)}>
                      Delete
                    </button>
                  </div>
                </div>
                <div className="content-block">
                  {this.state.message?.data?.map(x => x?.attributes?.message_type === "normal" ? <div className="chat-message-wrapper">
                    <div className={`${localStorage.getItem("id") === x?.attributes?.account?.data?.id ? "recive-message" : "send-message"}`}>
                      <p>
                        {x?.attributes?.body}
                      </p>
                    </div>
                  </div> : x?.attributes?.message_type === "scheduled_tour" ?
                    <div class={`pay-wrap 
                    ${localStorage.getItem("role") == "seller" ? "mv-l" : "mv-r"}`}
                    ><div className="payment-block">
                        <div className="head-block">
                          <div className="profile-block">
                            <img src={localStorage.getItem("profile_image") ? localStorage.getItem("profile_image") : imageAttachment} alt="" />
                          </div>
                          <span className="profile-name">{`${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`}</span>
                        </div>
                        <div className="content">
                          <div className="left-block">
                            <img src={x?.attributes?.tour_data?.data?.attributes?.property?.images?.[0]?.url ? x?.attributes?.tour_data?.data?.attributes?.property?.images?.[0]?.url : imageAttachment} alt="" />
                          </div>
                          <div className="right-block">
                            <p className="invite-tile">hii, {x?.attributes?.tour_data?.data?.attributes?.visitor?.first_name ? x?.attributes?.tour_data?.data?.attributes?.visitor?.first_name : "jhone"} {x?.attributes?.tour_data?.data?.attributes?.visitor?.last_name ? x?.attributes?.tour_data?.data?.attributes?.visitor?.last_name : "dow"} wants to visit your</p>
                            <span className="time-block">Date: {moment(x?.attributes?.tour_data?.data?.attributes?.tour_at).format("D MMMM yyyy A")}</span>
                            {/* <span className="time-block">Date: {x?.attributeszz?.tour_data?.data?.attributes?.tour_at ? moment(x?.attributes?.tour_data?.data?.attributes?.tour_at).format(""): "10 january, 2020 Morning"}</span> */}
                            <div className="note">
                              <span className="label">Note:</span>
                              <p className="description">{x?.attributes?.tour_data?.data?.attributes?.note ? x?.attributes?.tour_data?.data?.attributes?.note : "No Note Found"}</p>
                            </div>
                            <div className="btn-wrapper">
                              {x?.attributes?.tour_data?.data?.attributes?.status === "pending"
                                && Number(x?.attributes?.tour_data?.data?.attributes?.scheduler_id) !== Number(localStorage.getItem("id"))
                                ?
                                <>
                                  <button type="button" className="btn orange-fill-btn"
                                    onClick={() => {
                                      this.doScheduler(x?.attributes?.tour_data?.data?.attributes?.property_id, x?.attributes?.tour_data?.data?.id)
                                    }
                                    }>
                                    Accept
                                  </button>
                                  <button
                                    type="button"
                                    className="gray-border-btn"
                                    onClick={() => {
                                      this.dosetTourId(x?.attributes?.tour_data?.data?.id)
                                      this.handleShowTourModal(x?.attributes?.tour_data?.data?.attributes?.property_id);
                                    }}
                                  >
                                    Other Time
                                  </button>
                                </>
                                : x?.attributes?.tour_data?.data?.attributes?.status === "declined"
                                  && Number(x?.attributes?.tour_data?.data?.attributes?.scheduler_id) !== Number(localStorage.getItem("id"))
                                  ? <button type="button" className="btn orange-fill-btn"
                                    disabled
                                  >
                                    Declined
                                  </button> : x?.attributes?.tour_data?.data?.attributes?.status === "accepted"
                                  && Number(x?.attributes?.tour_data?.data?.attributes?.scheduler_id) !== Number(localStorage.getItem("id"))
                                  &&
                                  <button type="button" className="btn orange-fill-btn"
                                    disabled
                                  >
                                    Accepted
                                  </button>
                                // : <button type="button" className="gray-border-btn">
                                //   Cancel
                                // </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div> </div> : x?.attributes?.message_type === "property_offer" || x?.attributes?.message_type === "property_counter_offer" ? <>
                        {/* {this.doOfferType(x?.attributes?.message_type)} */}
                        {(x?.attributes?.offer_data) &&
                          <div
                            class={`pay-wrap ${(x?.attributes?.offer_data?.data?.type == "buyer_offer" && localStorage.getItem("role") == "buyer") ||
                              (x?.attributes?.offer_data?.data?.type == "seller_offer" && localStorage.getItem("role") == "seller") ? "mv-r" : "mv-l"}`}><div className="payment-block">
                              <div className="head-block">
                                <div className="profile-block">
                                  <img src={localStorage.getItem("profile_image") ? localStorage.getItem("profile_image") : imageAttachment} alt="" />
                                </div>
                                <span className="profile-name">{`${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`}</span>
                              </div>

                              <div className="content">
                                <div className="left-block">
                                  <img src={imageAttachment} alt="" />
                                </div>
                                <div className="right-block">
                                  <span className="heading">
                                    {x?.attributes?.message_type === "property_counter_offer" ? 'Seller' : 'Buyer'} Made Counter Offer
                                  </span>
                                  <div className="value-wrapper">
                                    <div className="title">Monthly payment : </div>
                                    <div className="value">${(+x?.attributes.offer_data?.data?.attributes?.property?.data?.attributes?.price - +x?.attributes.offer_data?.data?.attributes?.down_payment) / +x?.attributes.offer_data?.data?.attributes?.term_period}</div>
                                  </div>
                                  <div className="value-wrapper">
                                    <div className="title">Down payment : </div>
                                    <div className="value">${x?.attributes.offer_data?.data?.attributes?.down_payment}</div>
                                  </div>
                                {console.log("________________________________x______________________>", x)}
                                  {Number(x?.attributes?.offer_data?.data?.attributes?.offerer_id) !== Number(localStorage.getItem("id")) ?
                                    <div className="btn-wrapper">
                                      <button type="button" className="btn orange-fill-btn"
                                        onClick={() => {
                                          this.doSetCounterOfferDetail(x?.attributes.offer_data?.data?.id,
                                            x?.attributes.offer_data?.data?.attributes?.property?.data?.attributes?.price,
                                            x?.attributes.offer_data?.data?.attributes?.down_payment, x?.attributes?.message_type)
                                          this.doAcceptOffer(x?.attributes.offer_data?.data?.attributes?.property?.data?.id, localStorage.getItem("id"), x?.attributes.offer_data?.data?.id)
                                          // @ts-ignore
                                          // this.props?.history?.push({
                                          //   pathname: "/agreement",
                                          // });
                                        }}
                                      >
                                        Accept
                                      </button>
                                      <button type="button" className="gray-border-btn"
                                        onClick={() => {
                                          this.doSetCounterOfferDetail(x?.attributes.offer_data?.data?.id,
                                            x?.attributes.offer_data?.data?.attributes?.property?.data?.attributes?.price,
                                            x?.attributes.offer_data?.data?.attributes?.down_payment, x?.attributes?.message_type)
                                          this.setState({ counterOfferShow: true })
                                        }}
                                      >
                                        Counter Offer
                                      </button>
                                    </div>
                                    : <div className="btn-wrapper">
                                      <button type="button" className="btn orange-fill-btn"
                                        disabled
                                      >
                                        Offered
                                      </button>
                                    </div>
                                  }
                                </div>
                              </div>

                            </div></div>
                        }
                      </> : <div>{console.log("x", x)}New Type</div>)}


                  {this.state.message?.data?.map(x => x?.attributes?.attachments?.map(data =>
                    <div className="chat-message-wrapper">
                      <div className={`${localStorage.getItem("id") === x?.attributes?.account?.data?.id ? "recive-message" : "send-message"}`}>
                        <img src={data.url} alt="attachment" style={{ height: "25%", width: "25%" }} />
                      </div>
                    </div>
                  ))}

                  {/* {this.state.message  && Object.keys(this.state.message ).map(x=>console.log("x",this.state.message[x]))} */}
                  {/* <div className="payment-block">
                    <div className="head-block">
                      <div className="profile-block">
                        <img src={viewBuyerImage} alt="" />
                      </div>
                      <span className="profile-name">Stephen Cross</span>
                    </div>
                    <div className="content">
                      <div className="left-block">
                        <img src={imageAttachment} alt="" />
                      </div>
                      <div className="right-block">
                      <p className="invite-tile">hii, jhone dow wants to visit your</p>
                      <span className="time-block">Date: 10 january, 2020 Morning</span>
                      <div className="note">
                        <span className="label">Note:</span>
                        <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                      </div> 
                        <div className="btn-wrapper">
                          <button type="button" className="btn orange-fill-btn">
                            Accept
                          </button>
                          <button type="button" className="gray-border-btn">
                            Other Time
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* Formik */}
                  {/* <Formik
                    initialValues={{
                      body: "",
                      attachment: "",
                    }}
                    // validationSchema={Yup.object().shape(this.state.EmailSchema)}
                    onSubmit={(values): any => {
                      console.log(values);
                    }}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <div>
                          <span>
                            <input type="file" />
                          </span>
                          <span>
                            <Field type="text" name="body" placeholder="chat" />
                          </span>
                          <span><button >Send</button></span>
                        </div>
                      </Form>)}
                  </Formik> */}
                  {/* <div className="send-msg-block">
                    <Formik>
                      <Form>
                        <Field
                          type="text"
                          name="Keywords"
                          placeholder="MLS, Yard, etc…"
                          className="custom-input secondary-input"
                        />
                      </Form>
                    </Formik>
                  </div> */}
                  <div className="send-msg-section">
                    <Formik
                      initialValues={{
                        conversation_id: this.state.coversationId,
                        recipient_id: this.state.recipientId,
                        message: "",
                        attachments: [],
                      }}
                      // validationSchema={Yup.object().shape(this.state.EmailSchema)}
                      onSubmit={(values): any => {
                        if (values.message.length != "" && values.message.trim().length != 0 || values.attachments.length > 0)
                          this.doMessaging(values);
                        values.message = "";
                        values.attachments = [];
                      }}
                      enableReinitialize
                    >
                      {({ values, setFieldValue, errors }) => (
                        <Form>
                          <div className="message-sec">
                            <div className="attachment-div">
                              <label htmlFor="attachmentId">
                                <img alt="Attachment" className="btn-image" src={attachmentImg} />
                              </label>
                              <input
                                type="file"
                                name="attachments"
                                id="attachmentId"
                                className="custom-input secondary-input attachmentId"
                                onChange={(event) => {
                                  this.handleAttachement(event, setFieldValue);
                                }}
                                multiple
                              />
                            </div>
                            <div className="message-div">
                              <Field
                                type="text"
                                name="message"
                                placeholder="Message @Stephen Cross"
                                className="custom-input secondary-input"
                              />
                            </div>
                            <div className="sendBtn-div">
                              <button type="submit" className="sendbtn">
                                <img alt="sendImg" src={sendImg} />
                              </button>
                            </div>
                          </div>
                          <div>
                            {values.attachments.map(x => <span style={{ margin: "10px" }}>{x.name}</span>)}
                          </div>
                          {/* {JSON.stringify(values,null,null)} */}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tour Schedule1 */}
        <ScheduleTour
          showTourModalProp={this.state.scheduleTourShow}
          onHideTourModal={this.handleShowScheduleTour}
          propertyId={this.state.propertyId}
          tourId={this.state.tourId}
        />

        {/* Tour Schedule2 */}
        <Modal
          show={false}
          onHide={this.handleHideTourModal}
          dialogClassName="schedule-tour-modal"
          backdrop="static"
          aria-labelledby="buyer-schedule-tour-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Schedule Tour
            </Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              type: "",
              date: "",
              time: "",
              note: "",
              showtime: "",
            }}
            validationSchema={Yup.object().shape(this.state.tourSchema)}
            onSubmit={(values) => {
              this.doBuyerTour(values, this.state.propertyId);
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <Modal.Body>
                  <div className="schedule-div">

                    <div className="schedule-type-btn">
                      <div className="sche-btn-wrap">
                        <button
                          className={`btn ${values.type === "in_person"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "in_person");
                          }}
                        >
                          <span>In Person</span>
                        </button>
                        <button
                          className={`btn ${values.type === "live_video"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "live_video");
                          }}
                        >
                          <img src={videoImg} />
                          <span>Live Video</span>
                        </button>
                      </div>
                      <span className="error">
                        <ErrorMessage name="type" />
                      </span>
                    </div>


                    <div className="select-date-div">
                      <h6 className="schedule-title">Select Date</h6>
                      <ul className="select-date-ul">
                        {this.state.nextSevenDays.map((d) => (
                          <li
                            key={d[0]}
                            className={`delect-date-li ${values.date == d[1] ? "green-bg-li" : ""
                              }`}
                            onClick={() => setFieldValue("date", d[1])}
                          >
                            <p className="day">{d[3]}</p>
                            <p className="date">{d[2]}</p>
                          </li>
                          // <li
                          //   key={d.fullDate}
                          //   className={`delect-date-li ${values.date == d.fullDate ? "green-bg-li" : ""
                          //     }`}
                          //   onClick={() => setFieldValue("date", d.fullDate)}
                          // >
                          //   <p className="day">{d.day}</p>
                          //   <p className="date">{d.date}</p>
                          // </li>
                        ))}
                      </ul>
                      <span className="error">
                        <ErrorMessage name="date" />
                      </span>
                    </div>


                    <div className="select-time-div">
                      <h6 className="schedule-title">Select Time</h6>

                      <DatePicker
                        selected={values.showtime ? values.showtime : new Date()}
                        onChange={(time) => {
                          let t = time.toLocaleTimeString("it-IT");
                          setFieldValue(`time`, t);
                          setFieldValue("showtime", time);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="HH:mm aa"
                      />
                      <span className="error">
                        <ErrorMessage name="time" />
                      </span>
                    </div>

                    <div className="note-div">
                      <h6 className="schedule-title">Note</h6>

                      <div className="field-wrapper">
                        <Field
                          type="text"
                          name="note"
                          // placeholder={configJSON.styleHolder}
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="note" />
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    variant="primary"
                    type="submit"
                    className="rounded-fill-btn"
                  // onClick={this.props.onHide}
                  >
                    Schedule Tour
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>


        {/* Loader  */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>

        {/* Counter Offer */}
        <CounterOffer
          showCounterOfferModal={this.state.counterOfferShow}
          onHideCounterOfferModal={this.onHideCounterOfferModal}
          counterId={this.state.counterOfferId}
          price={this.state.propertyPrice}
          downPayment={this.state.downPayment}
        />
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(chatSchedule);

// Customizable Area End
