// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import {
    imageLike,
    imageLikeRed,
    imageView,
    buyerSkew,
    noImgAvailable
} from "../../assets";
import "react-datepicker/dist/react-datepicker.css";
import { Chart, registerables } from 'chart.js';
import FilterPropertyFlowController, {
    Props,
} from "./FilterPropertyFlowController.web";
Chart.register(...registerables);

export interface Props { }
interface S { }
class HomePropertyItem extends FilterPropertyFlowController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="top-block">
                    <div className="profile-block">
                        <i>
                            <img src={buyerSkew} alt="" />
                        </i>
                        <span>{`${this.props.propertyItemData?.attributes?.owner.first_name ? this.props.propertyItemData?.attributes?.owner.first_name : "Unknown"} ${this.props.propertyItemData?.attributes?.owner.last_name ? this.props.propertyItemData?.attributes?.owner.last_name : ""}`}</span>
                    </div>
                    {this.props.propertyItemData?.attributes?.is_favorited ? (
                        <div className="like-block" onClick={() => this.doRemoveFavoriteHouse(this.props.propertyItemData?.id)}>
                            <img src={imageLikeRed} alt="Liked image" />
                        </div>) : (
                        <div className="like-block" onClick={() => this.doMakeFavoriteHouse(this.props.propertyItemData?.id)}>
                            <img src={imageLike} alt="Like image" />
                        </div>
                    )}
                </div>
                <i className="property-image">
                    <img src={this.props.propertyItemData?.attributes?.images[0] ? this.props.propertyItemData?.attributes?.images[0]?.url : noImgAvailable} alt="Property Image" />

                    {/* <img src={this.props.propertyItemData?.attributes?.images?.[0]?.url} alt="Property Image" /> */}
                </i>
                <div className="like-view-block">
                    <div className="left-block">
                        <p className="lable">List Price</p>
                        <p className="price">{this.props.propertyItemData?.attributes?.formatted_price}</p>
                    </div>
                    <div className="right-block">
                        <div className="view">
                            <i>
                                <img src={imageView} alt="" />
                            </i>
                            <span>{this.props.propertyItemData?.attributes?.view_count}</span>
                        </div>
                        <div className="like">
                            <i>
                                <img src={imageLikeRed} alt="" />
                            </i>
                            <span>{this.props.propertyItemData?.attributes?.favorited_count}</span>
                        </div>
                    </div>
                </div>
                <Link to={{ pathname: "/property-detail/", search: `id=${this.props.propertyItemData?.id}`, state: this.props.propertyItemData?.id }} className="more-detail">
                    <p className="property-name">{this.props.propertyItemData?.attributes?.address.address_line}</p>
                    <p className="property-address">{`${this.props.propertyItemData?.attributes?.address.state} , ${this.props.propertyItemData?.attributes?.address.city} ${this.props.propertyItemData?.attributes?.address.zip_code}`}</p>
                    <ul>
                        <li>{this.props.propertyItemData?.attributes?.bedrooms} Beds</li>
                        <li>{this.props.propertyItemData?.attributes?.full_baths} Baths</li>
                        <li>{this.props.propertyItemData?.attributes?.finished_sq_ft} Sq ft</li>
                    </ul>
                </Link>
                <div className="btn-wrapper">
                    <button
                        type="button"
                        className="rounded-fill-btn"
                        onClick={() => { this.handleShowTourModal(this.props.propertyItemData?.id) }}
                    >
                        Schedule Tour
                    </button>
                    {this.state.compareHouseList?.find(home => home.id === this.props.propertyItemData?.id) ? (
                        <button
                            type="button"
                            className={`btn gray-fill-btn active `}
                            onClick={() => this.doRemoveCompareHouse(this.props.propertyItemData?.id)}
                        >
                            Remove to compare
                        </button>)
                        : (
                            <button
                                type="button"
                                className={`btn gray-fill-btn`}
                                onClick={() => this.doMakeCompareHouse(this.props.propertyItemData?.id)}
                            >
                                Add to compare
                            </button>)}
                </div>
            </>
        );
    }
}

export default HomePropertyItem;
