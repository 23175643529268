Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.fileContentType = "multipart/form-data";

// PlaceHolder
exports.addressHolder = "Enter Address";
exports.cityHolder = "Enter City";
exports.zipHolder = "Enter ZipCode";

exports.totalRoomHolder = "Ex. 1";
exports.bedroomsHolder = "Ex. 1";
exports.floorNoHolder = "Ex. 1";
exports.unitHolder = "";
exports.storiesHolder = "";
exports.fullBathsHolder = "";
exports.bathsHolder = "";
exports.styleHolder = "Ex: colonial, contemporary, tudor";
exports.squareFeetHolder = "Ex: 100";
exports.basementHolder = "Ex: 10000";
exports.lotSizeHolder = "Ex: 10000";
exports.garageHolder = "1, 2, etc.";
exports.yearBuiltHolder = "Ex: 2021";
exports.remodelYearHolder = "Ex: 2021";
exports.describeYourPlaceHolder = "Write about place…";
exports.loveAboutHolder =
  "Say what you love about it and what makes it unique, talk about the neighbourhood and lifestyle it provides.";

exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";
exports.TotalRoomHolder = "";

//Error
exports.errorAddressNotValid = "Please Enter Address";
exports.errorZipNotValid = "Please Enter Zip Code";
exports.errorHomeTypeNotValid = "Please Select HomeType";
exports.errorStatusNotValid = "Please Select Status";

exports.errortotalRoomNotValid = "Please Enter Number of Rooms";
exports.errorbedroomsNotValid = "Please Enter Number of Bedrooms";
exports.errorfloorNoNotValid = "Please Enter Number of Floors";
exports.errorunitNotValid = "Please Enter Number of Units";
exports.errorstoriesNotValid = "Please Enter Number of Stories";
exports.errorfullBathsNotValid = "Please Enter Number of Baths";
exports.errorbathsNotValid = "Please Enter Number of 1/2 Baths";
exports.errorstyleNotValid = "Please Enter Styles";
exports.errorsquareFeetNotValid = "Please Enter Number of Feets";
exports.errorbasementNotValid = "Please Enter Number of Basements";
exports.errorlotSizeNotValid = "Please Enter Number of Lot Size";
exports.errorgarageNotValid = "Please Enter Number of Garages";
exports.erroryearBuiltNotValid = "Please Enter Year of Built ";
exports.errorremodelYearNotValid = "Please Enter Remodel Year";
exports.errordescribeYourPlaceNotValid = "Please Enter Description";
exports.errorloveAboutNotValid = "Please Enter something you love about home";
exports.errorDownpayMustBeLess =
  "Please Enter Minimum Downpayment less than or equal to price";

// End Point
// bx_block_filteritems / filters;
exports.getAllHomeTypeListApiEndPoint =
  "bx_block_categoriessubcategories/home_types";
exports.getAllAddressDetailsApiEndPoints = "bx_block_postcreation/properties";
exports.getAllPropertyListApiEndPoints =
  "bx_block_postcreation/property_details";
exports.getAllBasicDetailsApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllNewPropertyListApiEndPoint =
  "bx_block_postcreation/public_properties/newly_added";
exports.getAllFavroiteApiEndPoints = "bx_block_postcreation/favorites";
exports.getAllCompareApiEndPoints = "bx_block_postcreation/comparisions";

//API TYPE
exports.getApiMethodType = "GET";
exports.putApiMethodType = "PUT";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.patchApiMethodType = "PATCH";

exports.successLogin = "Detail Add Successfully";
exports.successProfileUpdate = "Profile Update Successfully";
exports.successProfileAdd = "Profile Added Successfully";
exports.successProfileDelete = "Profile Delete Successfully";
exports.ApiFail = "API Fail";

//homepage-plaid
// PlaceHolder
exports.cityHolder = "Enter City";
exports.zipHolder = "Enter ZipCode";
exports.videoUrlHolder = "https://www.youtube.com";
exports.noteHolder = "Write note if any...";
exports.priceHolder = "Ex. 100,000";
exports.otherTaxHolder = "Ex. tax,fees..";
exports.phoneNumberHolder = "(555) 555 555 555";

exports.emailPlaceHolder = "Enter Email";
exports.firstNamePlaceHolder = "Enter First Name";
exports.middleNamePlaceHolder = "Enter Middle Name";
exports.lastNamePlaceHolder = "Enter Last Name";
exports.addressLinePlaceHolder = "Enter Address Line 1";
exports.cityPlaceHolder = "Enter City";
exports.statePlaceHolder = "Enter State";
exports.zipCodePlaceHolder = "Enter Zip Code";
exports.addressLine2PlaceHolder = "Enter Address Line 2";
exports.passwordPlaceHolder = "Enter Password";

//Errors
exports.errorMediaNotValid = "Please Upload Image";
exports.errorVideoUrlWalkthroughNotValid = "Please Enter Walkthrough Video URL";
exports.errorVideoUrlVirtualTourNotValid =
  "Please Select Virtual Tour Video URL";
exports.errorEnterCorrectURL = "Please Enter Corect URL";
exports.errorPriceNotValid = "Please Enter Property Price";
exports.errorMinDownpayNotValid = "Please Enter Minimum Downpayment";
exports.errorTermNotValid = "Please Choose Term";
exports.errorCurrencyNotValid = "Please Choose A Currency Type";
exports.errorContactNumberNotValid = "Please Enter Your Phone Number";
exports.loginFirst = "Please Login First";
exports.errorPositive = "Please Enter Positive Integer";
exports.errorInteger = "Please Enter Integer only";
exports.errorMinDwnPayment = "Please Enter Less Amount then Price";

exports.errorTypeNotValid = "Please Select Tour Type";
exports.errorTimeNotValid = "Please Select Time";
exports.errorDateNotValid = "Please Select Date";
exports.errorNoteNotValid = "Please Enter Note";

exports.errorBuyerFullNameNotValid = "Please Enter Your Full Name";
exports.errorBuyerEmailNotValid = "Please Enter Your Email Address";
exports.errorCoBuyerFullNameNotValid = "Please Enter Co-Buyer's Full Name";
exports.errorCoBuyerEmailNotValid = "Please Enter Co-Buyer's Email Address";
exports.errorPropertyVisitedNotValid =
  "Please Choose Your Property Visit Status";
exports.errorPaymentTypeNotValid = "Please Choose Payment Type";
exports.errorMoveInNotValid = "Please Choose Date For Moving In";
exports.errorEmailNotValid = "Please Enter Valid Email Address";
exports.errorContactMinValue =
  "Please Enter Contact Number Greater then 4 Digit";
exports.errorContactMaxValue = "Please Enter Contact Number Less then 12 Digit";

exports.errorFirstNameNotValid = "Please Enter First Name";
exports.errorMiddleNameNotValid = "Please Enter Middle Name";
exports.errorLastNameNotValid = "Please Enter Last Name";
exports.errorAddressLineNotValid = "Please Enter Address Line 1";
exports.errorCityNotValid = "Please Enter City";
exports.errorStateNotValid = "Please Enter State";
exports.errorZipCodeNotValid = "Please Enter Zip Code";
exports.errorAddressLine2NotValid = "Please Enter Address Line 2";
exports.errorPasswordNotValid = "Please Enter Password";

exports.errorEmailReq = "Please Enter Email Address";
//Other Texts
exports.utilityText =
  "Potential buyers will contact you through the email address you use to register on sparen. You must also add your phone number to the listing here.";
exports.termsCondition =
  "I agree to the following: (i) I am (or I have authority to act on behalf of) the owner of this home; (ii) I will not provide incorrect information or state a discriminatory preference ; and (iii) I will comply with the sparen Terms of Use Listings Quality Policy.";

//Api
exports.propertiesApiEndPoint = "bx_block_postcreation/properties/";
exports.getAllUploadMediaApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllPricingApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllPricingApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllCheckListApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllReviewApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllAmanitiesListApiEndPoints = "bx_block_postcreation/amenities";
exports.publishPropertyApiEndPoints = "bx_block_postcreation/properties/";

exports.getAllSearchFilterApiEndPoints = "bx_block_filteritems/filters";
exports.setBuyerTourApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllFilterApiEndPoints = "bx_block_filteritems/filters";
exports.getAllPropertyDetailsApiEndPoints = "bx_block_postcreation/properties/";
exports.getAllAccountDetailApiEndPoints = "bx_block_profile/profile";
exports.createTokenApiEndPoint =
  "bx_block_plaidapiintegrations/plaid/create_link_token";
exports.bankAccountApiEndPoint = "bx_block_plaidapiintegrations/bank_accounts";
exports.deleteBankAccountAPiEndPoint =
  "bx_block_plaidapiintegrations/bank_accounts/";

exports.getAllSimilarPropertyDetailsApiEndPoints =
  "bx_block_postcreation/public_properties/";

exports.getEmailSubscriptionApiEndPoint =
  "bx_block_emailnotifications/email_subscriptions";

exports.getAllPropertyTermsOfServicesContentApiEndPoints =
  "bx_block_adminconsole/static_pages/property-terms-of-services";

exports.getOwnerDetailApiEndPoints =
  "bx_block_profile/owner_details?seller_id=";
exports.getBuyerDetailApiEndPoints = "bx_block_profile/buyer_details?buyer_id=";
exports.agreementSignApiEndPoints = "agreement/add_signatures";

exports.errorOwnerSignNotValid = "Please Enter Owner Signature";
exports.errorBuyerSignNotValid = "Please Enter Buyer Signature";

exports.getStateApiEndPoints = "state?country_code=US";
exports.getCityApiEndPoints = "city";
exports.getAllPropertiesApiEndPoints = "bx_block_postcreation/properties";
exports.agreementApiEndPoint = "agreement";

// Customizable Area End
