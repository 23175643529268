// @ts-nocheck

import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  sparenLogo,
  imageContacts,
  imageDocuments,
  imageFinancials,
  imageMessages,
  imageProperty,
  imageDashboard,
} from "./Sidebar.assets";
import { Link } from "react-router-dom";
type PropTypes = {
  menuCollapsed: boolean;
};

const Sidebar = (props: PropTypes) => {
  const { menuCollapsed } = props;
  return (
    <>
      {console.log("PRRRRROOOPPPPSSS", props)}
      <div className="sidebar-overlay"></div>
      <div className="sidebar-wrapper buyer-sidebar">
        <div className="sidebar-content">
          {/* Logo */}
          <Link to={
            localStorage.getItem("role") === "buyer"
              ? "/dashboard-buyer"
              : "/dashboard-seller"
          }
            className="logo">
            <img src={sparenLogo} alt="SPAREN admin" />
            <span>Buyer Dashboard</span>
          </Link>
          {/* Sidebar Menu */}
          <div className="sidebar-menu-wrapper">
            <div className={`sidebar-link 
                ${props?.location?.pathname == "/dashboard-buyer"
                ? "active"
                : (props?.location?.pathname == "/dashboard-seller"
                  ? "active"
                  : "")}`
            }>
              <NavLink
                exact
                to={
                  localStorage.getItem("role") === "buyer"
                    ? "/dashboard-buyer"
                    : "/dashboard-seller"
                }
              >
                <i>
                  <img src={imageDashboard} alt="" />
                </i>
                <span>DashBoard</span>
              </NavLink>
            </div>
            <div className={`sidebar-link has-submenu
                ${props?.location?.pathname == "/property-list"
                ? "active"
                : ""}`
            }>
              <NavLink exact to="/property-list">
                <i>
                  <img src={imageProperty} alt="" />
                </i>
                <span>Property</span>
              </NavLink>
            </div>
            <div className={`sidebar-link has-submenu
                ${props?.location?.pathname == "/financials"
                ? "active"
                : ""}`
            }>
              <NavLink exact to={props?.location?.pathname}>
                <i>
                  <img src={imageFinancials} alt="" />
                </i>
                <span>Financials</span>
              </NavLink>
            </div>
            <div className={`sidebar-link
            ${props?.location?.pathname == "/chat-schedule"
                ? "active"
                : ""}`
            }>
              <NavLink exact to="/chat-schedule">
                <i>
                  <img src={imageMessages} alt="" />
                </i>
                <span>Messages</span>
              </NavLink>
            </div>
            <div className={`sidebar-link
                ${props?.location?.pathname == "/contact"
                ? "active"
                : ""}`
            }>
              <NavLink exact to="/contact">
                <i>
                  <img src={imageContacts} alt="" />
                </i>
                <span>Contacts</span>
              </NavLink>
            </div>
            <div className={`sidebar-link has-submenu
                ${props?.location?.pathname == "/documents"
                ? "active"
                : ""}`
            }>
              <NavLink exact to={props?.location?.pathname}>
                <i>
                  <img src={imageDocuments} alt="" />
                </i>
                <span>Documents</span>
              </NavLink>
            </div>
          </div>
          {/* <div className="btn-wrapper">
          <button
            className="rounded-fill-btn"
            onClick={() => props.history.push("/add-property/address-detail")}
          >
            + Add Property
          </button>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
