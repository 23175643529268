// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import {
    buyerLogo,
    filterLogo,
    sortIcon,
    imageDashboard,
    imageSetting,
    imageLikeFill,
    imageLogout, profieImg,
    noImgAvailable
} from "../../assets";
import { isEmpty, isEqual } from "lodash";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AppLoader from '../../../../../components/src/AppLoader.web';
// import ScheduleTour from "../ScheduleTour.web";
import { Chart, registerables } from 'chart.js';
import FilterPropertyFlowController, {
    Props,
} from "./FilterPropertyFlowController.web";
import HomePropertyItem from "./HomePropertyItem.web";
import MapForFilter from "./MapForFilter.web";
import FilterDropdown from "../../Popups/FilterDropdown.web";
Chart.register(...registerables);

export interface Props { }
interface S { }
class HomePropertyList extends FilterPropertyFlowController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
        super.componentDidMount();
        this.getCompareHouseList();
        this.doFavouriteListHouse();
        this.getPropertyLists();
        this.getHomeType();
        // @ts-ignore
        this.doInitFilter(this.props?.location?.search ? this.props?.location?.search : null);
    }
    render() {
        console.log("PAFDSSGF", this.state.propertyList)
        return (
            <>
                <header className="site-header fixed-header">
                    <div className="container-main">
                        <div className="left-header">
                            {localStorage.getItem("auth") ?
                                <Link to={
                                    localStorage.getItem("role") === "buyer"
                                        ? "/dashboard-buyer"
                                        : "/dashboard-seller"
                                } className="page-logo" title="Sparen Home">
                                    <img src={buyerLogo} alt="Logo" />
                                </Link>
                                : <Link to="/" className="page-logo" title="Sparen Home">
                                    <img src={buyerLogo} alt="Logo" />
                                </Link>
                            }

                            {/* // <Link to={
              //   localStorage.getItem("role") === "buyer"
              //   ? "/dashboard-buyer"
              //   : "/dashboard-seller"
              // } className="page-logo" title="Sparen Home">
              //   <img src={buyerLogo} alt="Logo" />
              // </Link> */}
                            <div className="link-wrapper">
                                <ul>
                                    <li>
                                        <Link to="/home-page-buyer" title="Buy" className="active">
                                            Buy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home-page-seller" title="Sell">
                                            Sell
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={this.props?.location?.pathname} title="How It Works">
                                            How It Works
                                        </Link>
                                    </li>
                                    <button
                                        className="rounded-fill-btn blue-fill sm-visible"
                                        onClick={() => this.props.history.push("signin")}
                                    >
                                        Log In
                                    </button>
                                    <button
                                        className="rounded-fill-btn blue-fill sm-visible"
                                        onClick={() => this.props.history.push("signup")}
                                    >
                                        Sign Up
                                    </button>
                                </ul>
                            </div>
                        </div>
                        <div className="right-header">
                            {localStorage.getItem("auth") ? (
                                <div className="profile-wrapper" onClick={this.doToggleHeaderDropdown}>
                                    <div className="icon">
                                        <span />
                                    </div>
                                    <div className="content">
                                        <div className="name">Hello , {`${!isEmpty(localStorage.getItem("first_name")) &&
                                            localStorage.getItem("first_name") !== "null"
                                            ? localStorage.getItem("first_name")
                                            : localStorage.getItem("email").split("@")[0]
                                            } ${!isEmpty(localStorage.getItem("last_name")) &&
                                                localStorage.getItem("last_name") !== "null"
                                                ? localStorage.getItem("last_name")
                                                : ""
                                            }`}</div>
                                        {/* <div
                  className="logout"
                  onClick={() => {
                    localStorage.clear();
                    this.props.history.push("/signin");
                  }}
                >
                  Logout
                </div> */}
                                    </div>
                                    <div className="profile-image">
                                        <img
                                            src={!isEmpty(localStorage.getItem("profile_image")) &&
                                                localStorage.getItem("profile_image") !== "null"
                                                ? localStorage.getItem("profile_image")
                                                : profieImg}
                                            alt="profile_image"
                                        />
                                    </div>
                                    {/* DropDown  */}
                                    {this.state.HeaderDropdownFlag &&
                                        <ul className="dropdown-list">
                                            <li>
                                                <Link to={
                                                    !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                                                        ? "/dashboard-buyer"
                                                        : "/dashboard-seller"
                                                } className="buyer-link">
                                                    Go to Buyer Account
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={
                                                    !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                                                        ? "/dashboard-buyer"
                                                        : "/dashboard-seller"
                                                }>
                                                    <img src={imageDashboard} alt="" />
                                                    <span>Dashboard</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/account-setting">
                                                    <img src={imageSetting} alt="" />
                                                    <span>Account Settings</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/favorite-homes">
                                                    <img src={imageLikeFill} alt="" />
                                                    <span>Favorite Homes</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/" onClick={() => {
                                                    localStorage.clear();
                                                    this.props?.history?.push("/signin");
                                                }}>
                                                    <img src={imageLogout} alt="" /> <span>Log out</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            ) : (
                                <div className="auth-btns-space">
                                    <button
                                        className="rounded-fill-btn green-fill btn-mr"
                                        onClick={() => this.props?.history?.push("/signin")}
                                    >
                                        Log In
                                    </button>
                                    <button
                                        className="rounded-fill-btn blue-fill"
                                        onClick={() => this.props?.history?.push("/signup")}
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="mobile-menu" onClick={this.toggleClass}>
                            <ul className={this.toggleMenu ? "active" : ""}>
                                <li />
                                <li />
                                <li />
                                <li />
                            </ul>
                        </div>
                    </div>
                </header>
                <div className="map-wrapper">
                    <div className="left-block">
                        <MapForFilter />
                    </div>
                    <div className="right-block">
                        <div className="filter-block-wrapper">
                            <div className="tab-wrapper">

                            </div>
                            <div className="right-filter">
                                <button
                                    className="sort-btn"
                                //   onClick={() => this.toggleFilter()}
                                >
                                    Sort
                                    <i>
                                        <img src={sortIcon} alt="" />
                                    </i>
                                </button>
                                <button
                                    className="filter-btn"
                                    onClick={() => this.toggleFilterFlag()}
                                >
                                    Filter
                                    <i>
                                        <img src={filterLogo} alt="" />
                                    </i>
                                </button>
                            </div>
                        </div>
                        <div className="property-wrapper">
                            {!isEmpty(this.state.propertyList) ?
                                this.state.propertyList?.map((property, index) =>
                                    this.state.propertyList?.length == index + 1 ?
                                        <div className="property-block" key={property.id} ref={this.lastPropertyItem}>
                                            <HomePropertyItem propertyItemData={property} refreshPropertyData={(id, isfav, message) => this.handleRefreshPropertyData(id, isfav, message)} />
                                        </div>
                                        : <div className="property-block" key={property.id}>
                                            <HomePropertyItem propertyItemData={property} refreshPropertyData={(id, isfav, message) => this.handleRefreshPropertyData(id, isfav, message)} />
                                        </div>)
                                : <div className="no-data"> <h2>No Data Found</h2></div>
                            }
                        </div>
                        {this.state.compareHouseList?.length > 1 &&
                            <button
                                className="rounded-fill-btn compare-home-count"
                                onClick={() => this.props?.history?.push("/compare-homes")}
                            >Compare ({this.state.compareHouseList?.length})</button>
                        }
                    </div>
                </div>

                {/* Filter  */}
                <FilterDropdown
                    filterData={this.state.filterData}
                    handleFilterFlagShow={this.toggleFilter}
                    filterFlag={this.state.FilterFlag}
                    homeTypeList={this.state.homeTypeList}
                    roomDetailList={this.state.roomDetailList}
                    setFilterData={this.doFilterHouse}
                />

                {/* Tour Schedule1 */}
                {/* <ScheduleTour showTourModalProp={this.state.scheduleTourShow} onHideTourModal={this.handleShowScheduleTour} propertyId={this.state.propertyId} /> */}

                {/* Loader  */}
                {this.state.loader && <AppLoader title="Loading" />}

                {/* Snackbar for display success and failed messages. */}
                <Snackbar
                    open={this.state.snackBar.show}
                    autoHideDuration={3000}
                    onClose={this.closeSnackBarHandler}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={this.state.snackBar.type}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>

            </>
        );
    }
}

export default withRouter(HomePropertyList);
