//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    sliderProperty1,
    sliderProperty2,
    sortIconWhite,
    checked
} from "../../assets";
import { Link, withRouter } from "react-router-dom";
import Checklist from "../../Popups/Checklist.web";
import HandOverController, { Props } from "../HandOverController.web";
import PropertyItemDetail from "../../Popups/PropertyItemDetail.web";
// Customizable Area End

class PropertyUnderContractItem extends HandOverController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (
            <>
                <div className="list-block" onClick={localStorage.getItem("role") == "seller" && this.handleShowPropItemDetail}>
                    <div className="property-detail">
                        <div className="image-block">
                            <img src={sliderProperty1} alt="" />
                        </div>
                        <div className="content">
                            <p className="title">37577 Susan St.</p>
                            <p className="sub-title">Sterling Heights, MI 48310</p>
                            <span className="status under-process">Under Process</span>
                        </div>
                    </div>
                    <div className="tab-wrapper prop-list">
                        <Link to="/agreement">
                            <div className="status-tab in-progress">Agreement</div>
                        </Link>
                        <div className="status-tab done stat-middle" onClick={this.handleCheckList}><img src={checked} />Check List</div>
                        <div className="status-tab">Payment</div>
                    </div>
                </div>
                <div className="list-block">
                    <div className="property-detail">
                        <div className="image-block">
                            <img src={sliderProperty1} alt="" />
                        </div>
                        <div className="content">
                            <p className="title">37577 Susan St.</p>
                            <p className="sub-title">Sterling Heights, MI 48310</p>
                            <span className="status on-rent">On Rent</span>
                        </div>
                    </div>
                    <div className="tab-wrapper prop-list">
                        <div className="status-tab in-progress maintanance">Maintanance Request</div>
                    </div>

                </div>
                <Checklist checkListShow={this.state.showCheckList} handleCheckList={this.handleCheckList} amanitiesList={this.state.amanitiesList} />
                <PropertyItemDetail propItemDetailShow={this.state.showPropItemDetail} handlepropItemDetailShow={this.handleShowPropItemDetail} />
            </>
        );
        // Customizable Area End       
    }
}

// Customizable Area Start
export default PropertyUnderContractItem;

// Customizable Area End
