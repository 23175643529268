import React from "react";

// Customizable Area Start
import {
    iconSearch,
    arrowBottom,
    soldProperty,
    forSale,
    forSaleWhite,
    soldPropertyWhite,
} from "../assets";
import PopupsController, { Props } from "./PopupsController.web";
import { Modal } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Customizable Area End


class FilterDropdown extends PopupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <Modal
                show={this.props.filterFlag}
                onHide={this.props.handleFilterFlagShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="filter-modal"
            >
                <Modal.Body>
                    <Formik
                        initialValues={{
                            city: this.props.filterData?.city || [],
                            sliderValue: this.props.filterData?.sliderValue || [this.props.filterData?.["price[min]"] ? this.props.filterData?.["price[min]"] / 1000 : 10, this.props.filterData?.["price[max]"] ? this.props.filterData?.["price[max]"] / 1000 : 0] || [0, 0],
                            property_state: this.props.filterData?.property_state || "",
                            bedValue: this.props.filterData?.bathValue || [this.props.filterData?.["bedrooms[min]"] ? this.props.filterData?.["bedrooms[min]"] * 10 : 0, this.props.filterData?.["bedrooms[max]"] ? this.props.filterData?.["bedrooms[max]"] * 10 : 0] || [0, 0],
                            bathValue: this.props.filterData?.bathValue || [this.props.filterData?.["bathrooms[min]"] ? this.props.filterData?.["bathrooms[min]"] * 10 : 0, this.props.filterData?.["bathrooms[max]"] ? this.props.filterData?.["bathrooms[max]"] * 10 : 0] || [0, 0],
                            saleFlag: true,
                            priceFlag: false,
                            roomsFlag: false,
                            moreFlag: false,
                            bathroomFlag: false,
                            homeTypeList: this.props.filterData?.homeTypeList || this.props.filterData?.home_type_id || [],
                            must_garage: this.props.filterData?.must_garage || false,
                            has_basement: {
                                basement: this.props.filterData?.["has_basement[basement]"] || false,
                                finish: this.props.filterData?.["has_basement[finished]"] || false,
                                unfinish: this.props.filterData?.["has_basement[unfinished]"] || false,
                            },
                            single_story: this.props.filterData?.single_story || "",
                            view_id: this.props.filterData?.view_id || [],
                            repaired: this.props.filterData?.repaired || false,
                            must_have_ac: this.props.filterData?.must_have_ac || false,
                            must_have_pool: this.props.filterData?.must_have_pool || false,
                            waterfront: this.props.filterData?.waterfront || false,
                            hoa: this.props.filterData?.hoa || "",
                            parking_spots: this.props.filterData?.parking_spots || "",
                            sq_ft_from: this.props.filterData?.sq_ft_from || "",
                            sq_ft_to: this.props.filterData?.sq_ft_to || "",
                            lotSqft_from: this.props.filterData?.lotSqft_from || "",
                            lotSqft_to: this.props.filterData?.lotSqft_to || "",
                            built_from: this.props.filterData?.built_from || "",
                            built_to: this.props.filterData?.built_to || "",
                            last_sold: this.props.filterData?.last_sold || "",
                            Keywords: this.props.filterData?.Keywords || "",
                            search: this.props.filterData?.search || ""
                        }}
                        validationSchema={Yup.object().shape(this.state.filterSchema)}
                        onSubmit={(values) => {
                            console.log("values=======>", values);
                            this.sendFilterData(values)
                        }
                        }
                        enableReinitialize
                    // 
                    >
                        {({ values, setFieldValue, errors, resetForm }) => (
                            //@ts-ignore
                            <Form>
                                <div className="filter-header">
                                    <Field
                                        type="text"
                                        name="search"
                                        placeholder="Search by Location,pincode..."
                                    // className="custom-input secondary-input"
                                    />

                                    {/* <input type='text'
                      value={values.search}
                      onChange={(e) => setFieldValue("search", e.target.value)}
                    /> */}

                                    {/* <CustomizedHook
                      setOptions={this.state.stateDropdown}
                      getValue={(value) =>{
                        if(values.city.length !== value.length){
                          setFieldValue("city",value)
                        }
                        }
                      } 
                    />*/}
                                    <i className="search-icon">
                                        <img src={iconSearch} alt="" />
                                    </i>
                                </div>
                                <div className="filter-body">
                                    <div className="clear-filter">
                                        <div className="left-filter">filters</div>
                                        <div className="right-filter" onClick={() => resetForm()}>Clear Filter</div>
                                    </div>
                                    <div className="filter-wrapper">
                                        <div className={
                                            "filter-tab " + (values.saleFlag ? "active" : "")
                                        }
                                            onClick={() => {
                                                setFieldValue("saleFlag", true);
                                                setFieldValue("priceFlag", false);
                                                setFieldValue("roomsFlag", false);
                                                setFieldValue("moreFlag", false);
                                                setFieldValue("bathroomFlag", false);
                                            }}>
                                            <span>For Sale</span>
                                            <i>
                                                <img src={arrowBottom} alt="" />
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                "filter-tab " + (values.priceFlag ? "active" : "")
                                            }
                                            onClick={() => {
                                                setFieldValue("saleFlag", false);
                                                setFieldValue("priceFlag", true);
                                                setFieldValue("roomsFlag", false);
                                                setFieldValue("bathroomFlag", false);
                                                setFieldValue("moreFlag", false);
                                            }}
                                        >
                                            <span>Price</span>
                                            <i>
                                                <img src={arrowBottom} alt="" />
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                "filter-tab " + (values.bathroomFlag ? "active" : "")
                                            }
                                            onClick={() => {
                                                setFieldValue("saleFlag", false);
                                                setFieldValue("priceFlag", false);
                                                setFieldValue("roomsFlag", false);
                                                setFieldValue("bathroomFlag", true);
                                                setFieldValue("moreFlag", false);
                                            }}
                                        >
                                            <span>2+ Bd, 2+ Ba</span>
                                            <i>
                                                <img src={arrowBottom} alt="" />
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                "filter-tab " + (values.roomsFlag ? "active" : "")
                                            }
                                            onClick={() => {
                                                setFieldValue("saleFlag", false);
                                                setFieldValue("priceFlag", false);
                                                setFieldValue("roomsFlag", true);
                                                setFieldValue("bathroomFlag", false);
                                                setFieldValue("moreFlag", false);
                                            }}
                                        >
                                            <span>Home Type</span>
                                            <i>
                                                <img src={arrowBottom} alt="" />
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                "filter-tab " + (values.moreFlag ? "active" : "")
                                            }
                                            onClick={() => {
                                                setFieldValue("saleFlag", false);
                                                setFieldValue("priceFlag", false);
                                                setFieldValue("roomsFlag", false);
                                                setFieldValue("bathroomFlag", false);
                                                setFieldValue("moreFlag", true);
                                            }}
                                        >
                                            <span>More</span>
                                            <i>
                                                <img src={arrowBottom} alt="" />
                                            </i>
                                        </div>
                                    </div>
                                    {/* for sale block  */}
                                    {values.saleFlag && (
                                        <div className="filter-content">
                                            <div className="sale-block-wrapper">
                                                <div
                                                    className={`sale-block ${values.property_state === "for_sale" && "active"}`}
                                                    onClick={() => setFieldValue("property_state", "for_sale")}
                                                >
                                                    <img src={soldProperty} alt="" />
                                                    <img className="active-image" src={soldPropertyWhite} alt="" />
                                                    <span>For Sale</span>
                                                </div>
                                                <div
                                                    className={`sale-block ${values.property_state === "sold" && "active"}`}
                                                    onClick={() => setFieldValue("property_state", "sold")}
                                                >
                                                    <img src={forSale} alt="" />
                                                    <img className="active-image" src={forSaleWhite} alt="" />
                                                    <span>Sold</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* Select Price Range Slider  */}
                                    {values.priceFlag && (
                                        <div className="filter-content">
                                            <div className="slider-title">Select Price Range</div>
                                            <span className="prize-range">{values.sliderValue?.[0] * 1000} $ - {values.sliderValue?.[1] * 1000} $</span>
                                            <div className="slider-wrapper orange-slider">
                                                <Slider
                                                    value={values.sliderValue}
                                                    scale={(x) => x * 1000}
                                                    valueLabelFormat={(limit: number) => `$${limit}`}
                                                    onChange={(e, newValue) =>
                                                        setFieldValue("sliderValue", newValue)
                                                    }
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* Select Bathroom Slider  */}
                                    {values.bathroomFlag && (
                                        <div className="filter-content">
                                            <div className="slider-title">Beds</div>
                                            <div className="slider-wrapper orange-slider">
                                                <Slider
                                                    value={values.bedValue}
                                                    scale={(x) => x / 10}
                                                    step={10}
                                                    defaultValue={values.bedValue}
                                                    valueLabelFormat={(limit: number) => `${limit}`}
                                                    onChange={(e, newValue) =>
                                                        setFieldValue("bedValue", newValue)
                                                    }
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                />
                                            </div>
                                            <div className="slider-title">Baths</div>
                                            <div className="slider-wrapper orange-slider">
                                                <Slider
                                                    value={values.bathValue}
                                                    scale={(x) => x / 10}
                                                    step={10}
                                                    valueLabelFormat={(limit: number) => `${limit}`}
                                                    onChange={(e, newValue) =>
                                                        setFieldValue("bathValue", newValue)
                                                    }
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* Select Home Type  */}
                                    {values.roomsFlag && (
                                        <div className="filter-content">
                                            <div className="slider-title">Select Home Type</div>
                                            <div className="check-outer">
                                                {this.props.homeTypeList.map(
                                                    (homeType: any, index: any) => (
                                                        <div className="checkbox-wrapper" key={index}>
                                                            <label htmlFor={homeType.id}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={homeType.id}
                                                                    key={homeType.id}
                                                                    defaultChecked={values.homeTypeList.includes(homeType.id)}
                                                                    name={homeType.attributes.title}
                                                                    className="input-checkbox"
                                                                    onClick={() => {
                                                                        let array = Object.assign(
                                                                            [],
                                                                            values.homeTypeList
                                                                        );
                                                                        const idx = array.findIndex((el: any) =>
                                                                            el === homeType.id
                                                                        );
                                                                        if (idx === -1) {
                                                                            array = array.concat(homeType.id);
                                                                        } else {
                                                                            array.splice(idx, 1);
                                                                        }
                                                                        setFieldValue("homeTypeList", array);
                                                                    }}
                                                                />
                                                                {homeType.attributes.title}
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {/* MORE  */}
                                    {values.moreFlag && (
                                        <div className="filter-content more-filter">
                                            <div className="left-block">
                                                <div className="field-wrapper">
                                                    <label className="mb-1">Max HOA</label>
                                                    <Field
                                                        type="text"
                                                        as="select"
                                                        name="hoa"
                                                        autoComplete="off"
                                                        defaultValue=""
                                                        className="custom-input secondary-input "
                                                    >
                                                        <option value="" >
                                                            Any
                                                        </option>
                                                        <option value="no_fee">No HOA Fee</option>
                                                        <option value="50$">$ 50/month</option>
                                                        <option value="100$">$ 100/month</option>
                                                        <option value="200$">$ 200/month</option>
                                                        <option value="300$">$ 300/month</option>
                                                    </Field>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label className="mb-1">Parking Spots</label>
                                                    <Field
                                                        type="text"
                                                        as="select"
                                                        name="parking_spots"
                                                        autoComplete="off"
                                                        defaultValue=""
                                                        className="custom-input secondary-input "
                                                    >
                                                        <option value="" >
                                                            Any
                                                        </option>
                                                        <option value="1">1+</option>
                                                        <option value="2">2+</option>
                                                        <option value="3">3+</option>
                                                        <option value="4">4+</option>
                                                    </Field>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label />
                                                    <div className="checkbox-wrapper">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="garage"
                                                                name="must_garage"
                                                                defaultChecked={values.must_garage}
                                                                className="input-checkbox"
                                                                onClick={() =>
                                                                    setFieldValue(
                                                                        "must_garage",
                                                                        !values.must_garage
                                                                    )
                                                                }
                                                            />
                                                            Must have garage
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label>Square Feet</label>
                                                    <div className="select-wrapper">
                                                        <Field
                                                            type="text"
                                                            as="select"
                                                            name="sq_ft_from"
                                                            autoComplete="off"
                                                            defaultValue=""
                                                            className="custom-input secondary-input "
                                                        >
                                                            <option value="" >
                                                                Any
                                                            </option>
                                                            <option value="250">250</option>
                                                            <option value="500">500</option>
                                                            <option value="750">750</option>
                                                            <option value="1000">1000</option>
                                                            <option value="1250">1250</option>
                                                            <option value="1500">1500</option>
                                                            <option value="2000">2000</option>
                                                            <option value="2500">2500</option>
                                                            <option value="3000">3000</option>
                                                        </Field>
                                                        <span className="dash" />
                                                        <Field
                                                            type="text"
                                                            as="select"
                                                            name="sq_ft_to"
                                                            autoComplete="off"
                                                            defaultValue=""
                                                            className="custom-input secondary-input "
                                                        >
                                                            <option value="" >
                                                                Any
                                                            </option>
                                                            <option value="250">250</option>
                                                            <option value="500">500</option>
                                                            <option value="750">750</option>
                                                            <option value="1000">1000</option>
                                                            <option value="1250">1250</option>
                                                            <option value="1500">1500</option>
                                                            <option value="2000">2000</option>
                                                            <option value="2500">2500</option>
                                                            <option value="3000">3000</option>
                                                        </Field>
                                                    </div>
                                                    <span className="error">
                                                        <ErrorMessage name="sq_ft_from" />
                                                    </span>
                                                    <span className="error">
                                                        <ErrorMessage name="sq_ft_to" />
                                                    </span>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label>Lot Size </label>
                                                    <div className="select-wrapper">
                                                        <Field
                                                            type="text"
                                                            as="select"
                                                            name="lotSqft_from"
                                                            autoComplete="off"
                                                            defaultValue=""
                                                            className="custom-input secondary-input "
                                                        >
                                                            <option value="" >
                                                                Any
                                                            </option>
                                                            <option value="250">250</option>
                                                            <option value="500">500</option>
                                                            <option value="750">750</option>
                                                            <option value="1000">1000</option>
                                                            <option value="1250">1250</option>
                                                            <option value="1500">1500</option>
                                                            <option value="2000">2000</option>
                                                            <option value="2500">2500</option>
                                                            <option value="3000">3000</option>
                                                        </Field>
                                                        <span className="dash" />
                                                        <Field
                                                            type="text"
                                                            as="select"
                                                            name="lotSqft_to"
                                                            autoComplete="off"
                                                            defaultValue=""
                                                            className="custom-input secondary-input "
                                                        >
                                                            <option value="" >
                                                                Any
                                                            </option>
                                                            <option value="250">250</option>
                                                            <option value="500">500</option>
                                                            <option value="750">750</option>
                                                            <option value="1000">1000</option>
                                                            <option value="1250">1250</option>
                                                            <option value="1500">1500</option>
                                                            <option value="2000">2000</option>
                                                            <option value="2500">2500</option>
                                                            <option value="3000">3000</option>
                                                        </Field>
                                                    </div>
                                                    <span className="error">
                                                        <ErrorMessage name="lotSqft_from" />
                                                    </span>
                                                    <span className="error">
                                                        <ErrorMessage name="lotSqft_to" />
                                                    </span>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label>Year Built </label>
                                                    <div className="select-wrapper">
                                                        <Field
                                                            type="number"
                                                            name="built_from"
                                                            placeholder="Min"
                                                            className="custom-input secondary-input"
                                                        />
                                                        <span className="dash" />
                                                        <Field
                                                            type="number"
                                                            name="built_to"
                                                            placeholder="Max"
                                                            className="custom-input secondary-input"
                                                        />
                                                    </div>
                                                    <span className="error">
                                                        <ErrorMessage name="type" />
                                                    </span>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label className="mb-1">Sold In Last</label>
                                                    <Field
                                                        type="text"
                                                        as="select"
                                                        name="last_sold"
                                                        autoComplete="off"
                                                        defaultValue=""
                                                        className="custom-input secondary-input "
                                                    >
                                                        <option value="">
                                                            Any
                                                        </option>
                                                        <option value="1">1 month</option>
                                                        <option value="6">6 months</option>
                                                        <option value="12">12 months</option>
                                                        <option value="24">24 months</option>
                                                        <option value="36">36 months</option>
                                                    </Field>
                                                </div>
                                                <div className="field-wrapper">
                                                    <label className="mb-1">Keywords</label>
                                                    <Field
                                                        type="text"
                                                        name="Keywords"
                                                        placeholder="MLS, Yard, etc…"
                                                        className="custom-input secondary-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="right-block">
                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="has_basement.basement"
                                                            name="has_basement.basement"
                                                            className="input-checkbox"
                                                            checked={values.has_basement.basement}
                                                            onClick={() => {
                                                                let obj = Object.assign(
                                                                    {},
                                                                    values.has_basement
                                                                );
                                                                if (obj.basement) {
                                                                    let data = {
                                                                        basement: false,
                                                                        finish: false,
                                                                        unfinish: false,
                                                                    };
                                                                    setFieldValue("has_basement", data);
                                                                } else {
                                                                    obj.basement = true;
                                                                    setFieldValue("has_basement", obj);
                                                                }
                                                            }}
                                                        />
                                                        Has Basement
                                                        <span className="checkmark" />
                                                    </label>
                                                    <div className="sub-checkbox">
                                                        <div className="checkbox-wrapper">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="has_basement.finish"
                                                                    name="has_basement.finish"
                                                                    checked={values.has_basement.finish}
                                                                    className="input-checkbox"
                                                                    onClick={() => {
                                                                        let obj = Object.assign(
                                                                            {},
                                                                            values.has_basement
                                                                        );
                                                                        if (obj.basement === false) {
                                                                            let data = {
                                                                                basement: false,
                                                                                finish: false,
                                                                                unfinish: false,
                                                                            };
                                                                            setFieldValue("has_basement", data);
                                                                        } else {
                                                                            if (obj.unfinish) {
                                                                                obj.unfinish = !obj.unfinish;
                                                                            }
                                                                            obj.finish = !obj.finish;

                                                                            setFieldValue("has_basement", obj);
                                                                        }
                                                                    }}
                                                                />
                                                                Finished
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                        <div className="checkbox-wrapper">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="basement"
                                                                    name="has_basement.unfinish"
                                                                    checked={values.has_basement.unfinish}
                                                                    className="input-checkbox"
                                                                    onClick={() => {
                                                                        let obj = Object.assign(
                                                                            {},
                                                                            values.has_basement
                                                                        );
                                                                        if (obj.basement === false) {
                                                                            let data = {
                                                                                basement: false,
                                                                                finish: false,
                                                                                unfinish: false,
                                                                            };
                                                                            setFieldValue("has_basement", data);
                                                                        } else {
                                                                            if (obj.finish) {
                                                                                obj.finish = !obj.finish;
                                                                            }
                                                                            obj.unfinish = !obj.unfinish;
                                                                            setFieldValue("has_basement", obj);
                                                                        }
                                                                    }}
                                                                />
                                                                Unfinished
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="single_story"
                                                            name="single_story"
                                                            className="input-checkbox"
                                                            checked={values.single_story}
                                                            onClick={() =>
                                                                setFieldValue(
                                                                    "single_story",
                                                                    !values.single_story
                                                                )
                                                            }
                                                        />
                                                        Single Story Only
                                                        <span className="checkmark" />
                                                    </label>
                                                </div>
                                                <div className="checkbox-title">Other Amenities</div>
                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="must_have_ac"
                                                            name="must_have_ac"
                                                            defaultChecked={values.must_have_ac}
                                                            className="input-checkbox"
                                                            onClick={() => {
                                                                setFieldValue("must_have_ac", !values.must_have_ac);
                                                            }}
                                                        />
                                                        Must have AC
                                                        <span className="checkmark" />
                                                    </label>
                                                </div>
                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="must_have_pool"
                                                            name="must_have_pool"
                                                            className="input-checkbox"
                                                            onClick={() => {
                                                                setFieldValue("must_have_pool", !values.must_have_pool);
                                                            }}
                                                        />
                                                        Must have Pool
                                                        <span className="checkmark" />
                                                    </label>
                                                </div>
                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="waterfront"
                                                            name="waterfront"
                                                            className="input-checkbox"
                                                            onClick={() => {
                                                                setFieldValue("waterfront", !values.waterfront);
                                                            }}
                                                        />
                                                        Waterfront
                                                        <span className="checkmark" />
                                                    </label>
                                                </div>
                                                <div className="check-outer">
                                                    <div className="checkbox-title w-full">View</div>
                                                    {this.props.roomDetailList?.outside_views?.data?.map((vw: any) => {
                                                        return (vw.attributes.name !== "Other" && <div className="checkbox-wrapper" key={vw.id}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="view_id"
                                                                    name="view_id"
                                                                    className="input-checkbox"
                                                                    onClick={() => {
                                                                        let array = Object.assign(
                                                                            [],
                                                                            values.view_id
                                                                        );
                                                                        const idx = array.findIndex((el: any) =>
                                                                            el === vw.id
                                                                        );
                                                                        if (idx === -1) {
                                                                            array = array.concat(vw.id);
                                                                        } else {
                                                                            array.splice(idx, 1);
                                                                        }
                                                                        setFieldValue("view_id", array);
                                                                    }}
                                                                />
                                                                {vw.attributes.name}
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="repaired"
                                                            name="repaired"
                                                            className="input-checkbox"
                                                            checked={values.repaired}
                                                            onClick={() =>
                                                                setFieldValue("repaired", !values.repaired)
                                                            }
                                                        />
                                                        Moved-in ready homes, Evaluated And repaired by
                                                        Sparen
                                                        <span className="checkmark" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* {
                      <pre>
                        {JSON.stringify(values, null, 2)}
                      </pre>
                    } */}
                                    <div className="btn-wrapper">
                                        <button type="submit" className="rounded-fill-btn">
                                            Search
                                        </button>
                                        <button
                                            type="button"
                                            className="btn gray-fill-btn"
                                            onClick={this.props.handleFilterFlagShow}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default FilterDropdown;
// Customizable Area End
