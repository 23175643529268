//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import PopupsController, { Props } from "./PopupsController.web";
import { imgClose, infoImg, homeRepair, fixImg, arrowDown, workingImg, pricetagImg, screwdriveImg } from "../assets";
export const configJSON = require("../../config");

// Customizable Area End
class Checklist extends PopupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            <Modal
                show={this.props.checkListShow}
                onHide={this.props.handleCheckList}
                dialogClassName="checklist-modal summary-lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-header">
                    <div className="modal-header-wrap">
                        <div className="m-title">Buyer Check List</div>
                        <button type="button" className="close" onClick={() => {
                            this.props.handleCheckList();
                            this.handleStep("close")
                        }}>
                            <img src={imgClose} />
                            {/* <span aria-hidden="true">�</span>
                            <span className="sr-only">Close</span> */}
                        </button>
                    </div>
                </div>
                <Modal.Body>
                    <div>
                        <div className="modal-wrapper">
                            <div className="content-wrapper">
                                <div className="m-info">
                                    <img src={infoImg} />
                                    <p>Please check all the below details before taking pusation. If required then
                                        ask owner for replacement.</p>
                                </div>

                                <div className="checklist-sections">
                                    <Formik
                                        initialValues={{
                                            propertyAmenities: this.props.amanitiesList && this.props.amanitiesList != 0 &&
                                                this.props.amanitiesList.map((amanitiesType: any, index) => ({
                                                    id: "",
                                                    amenity_id: +amanitiesType.id,
                                                    status: "na",
                                                    note: "",
                                                })),
                                        }}
                                        // validationSchema={Yup.object().shape(this.props.verifyOtpSchema)}

                                        onSubmit={(values) => {
                                            // this.doCheckList(values);

                                        }}
                                        enableReinitialize
                                    >
                                        {({ values, setFieldValue, errors }) => (
                                            //@ts-ignore
                                            <Form>
                                                {/* Step 1 */}
                                                {this.state.step == 1 &&
                                                    (<div className="checklist-sub-step">
                                                        <div className="checklist-sub-sec">
                                                            <div className="checklist-heading">
                                                                <span className="checklist-title">Amenities</span>
                                                            </div>

                                                            <div className="sub-section">
                                                                <FieldArray name="propertyAmenities">
                                                                    {({ push, remove }) => (
                                                                        <>
                                                                            {this.props.amanitiesList &&
                                                                                this.props.amanitiesList.map(
                                                                                    (amanitiesType: any, index) => (
                                                                                        <div
                                                                                            className="field-wrapper"
                                                                                            key={amanitiesType.id}
                                                                                        >
                                                                                            <p className="sec-sub-title">
                                                                                                {amanitiesType.attributes.name}
                                                                                            </p>
                                                                                            <button
                                                                                                className={
                                                                                                    values.propertyAmenities[index]?.status ==
                                                                                                        "working"
                                                                                                        ? "btn step-btn orange-btn"
                                                                                                        : "btn step-btn bordered-btn"
                                                                                                }
                                                                                                type="button"
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    setFieldValue(
                                                                                                        `propertyAmenities[${index}].status`,
                                                                                                        "working"
                                                                                                    );

                                                                                                }}
                                                                                            >
                                                                                                This Works
                                                                                            </button>
                                                                                            <button
                                                                                                className={
                                                                                                    values.propertyAmenities[index]?.status ==
                                                                                                        "not_working"
                                                                                                        ? "btn step-btn orange-btn"
                                                                                                        : "btn step-btn bordered-btn"
                                                                                                }
                                                                                                type="button"
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    setFieldValue(
                                                                                                        `propertyAmenities[${index}].status`,
                                                                                                        "not_working"
                                                                                                    );

                                                                                                }}
                                                                                            >
                                                                                                Does Not Work
                                                                                            </button>
                                                                                            {values.propertyAmenities[index]?.status ===
                                                                                                "not_working" && (
                                                                                                    <Field
                                                                                                        type="text"
                                                                                                        name={`propertyAmenities[${index}].note`}
                                                                                                        placeholder={configJSON.noteHolder}
                                                                                                        className="custom-input secondary-input sec-input"
                                                                                                    />
                                                                                                )}
                                                                                            <span className="error">
                                                                                                <ErrorMessage
                                                                                                    name="new"
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                            </div>
                                                        </div>

                                                        {/* Next  */}
                                                        <div className="btn-wrapper flex justify-between">
                                                            <div className="flex action-check">
                                                                <button type="button" className="btn gray-fill-btn c-btn-action" >
                                                                    Back
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="rounded-fill-btn ml-3 c-btn-action"
                                                                    onClick={() => this.handleStep("next")}>
                                                                    Next
                                                                </button>
                                                            </div>
                                                            <button type="submit" className="btn gray-fill-btn ml-3 c-btn-termi">
                                                                Terminate Agreement
                                                            </button>
                                                        </div>
                                                    </div>)
                                                }

                                                {/* Step 2 */}
                                                {this.state.step == 2 &&
                                                    (<div className="checklist-sub-step">
                                                        <div className="home-reapir flex justify-center">
                                                            <img src={homeRepair} />
                                                        </div>
                                                        <div className="home-reapir-info">
                                                            <p>5 Items needs to fix</p>
                                                        </div>
                                                        <div className="btn-wrapper flex justify-center">
                                                            <button type="button" className="btn gray-fill-btn" onClick={() => this.handleStep("prev")}>
                                                                Back
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="rounded-fill-btn ml-3"
                                                                onClick={() => this.handleStep("next")}
                                                            >
                                                                Continue Payment
                                                            </button>
                                                        </div>
                                                    </div>)
                                                }

                                                {/* Step 3 */}
                                                {this.state.step == 3 &&
                                                    (<div className="checklist-sub-step flex">
                                                        <div className="home-sub-section">
                                                            <Accordion>
                                                                <Card>
                                                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                                                        <div className="left-title">
                                                                            <div className="image-block">
                                                                                <img src={fixImg} alt="" />
                                                                            </div>
                                                                            <span>Need To Fix</span>
                                                                        </div>
                                                                        <div className="right-title">
                                                                            <div className="count-block">10                                                                           </div>
                                                                            <span>Items</span>
                                                                            <div className="arrow-icon">
                                                                                <img src={arrowDown} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Toggle>
                                                                    <Accordion.Collapse eventKey="0">
                                                                        <Card.Body>
                                                                            <div className="amenities-status">
                                                                                <p>Water Tank</p>
                                                                            </div>
                                                                            <div className="amenities-status">
                                                                                <p>Garage</p>
                                                                            </div>
                                                                            <div className="amenities-status">
                                                                                <p>Parking</p>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                                <Card>
                                                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                                                        <div className="left-title">
                                                                            <div className="image-block">
                                                                                <img src={workingImg} alt="" />
                                                                            </div>
                                                                            <span>Working</span>
                                                                        </div>
                                                                        <div className="right-title">
                                                                            <div className="count-block">10
                                                                            </div>
                                                                            <span>Items</span>
                                                                            <div className="arrow-icon">
                                                                                <img src={arrowDown} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Toggle>
                                                                    <Accordion.Collapse eventKey="1">
                                                                        <Card.Body>
                                                                            <div className="amenities-status">
                                                                                <p>Water Tank</p>
                                                                            </div>
                                                                            <div className="amenities-status">
                                                                                <p>Garage</p>
                                                                            </div>
                                                                            <div className="amenities-status">
                                                                                <p>Parking</p>
                                                                            </div>

                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </Accordion>
                                                        </div>
                                                        <div className="home-repair-action">
                                                            <div className="home-repair-type flex">
                                                                <img src={pricetagImg} />
                                                                <div className="home-repair-data">
                                                                    <p className="data-title">Ask to Adjust Price</p>
                                                                    <p className="data-value">$100,000</p>
                                                                </div>
                                                            </div>
                                                            <div className="home-repair-type flex">
                                                                <img src={screwdriveImg} />
                                                                <div className="home-repair-data">
                                                                    <p className="data-title">Ask for Repair Items</p>
                                                                    <p className="data-value">10 Items</p>
                                                                </div>
                                                            </div>
                                                            <p className="home-repair-or">or</p>
                                                            <button className="home-repair-termi">Terminate Agreement</button>
                                                        </div>
                                                    </div>)
                                                }
                                            </Form>
                                        )}
                                    </Formik>
                                </div>




                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

// Customizable Area Start
export default Checklist;

// Customizable Area End
